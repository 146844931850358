<template>
  <div class="item-app-card">
    <img src="@/assets/app-cover.png" class="cover" />
    <div class="detail">
      <div class="title"><span>Untitled</span><i class="el-icon-more"></i></div>
      <div class="time">2024-11-27&nbsp;12:00</div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  methods: {
    updateValue(value) {
      this.emit('input', value)
    }
  }
}
</script>
<style>
.item-app-card {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  width: 328px;
  height: 435px;
}

.cover {
  width: 100%;
  object-fit: cover;
}

.detail {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.detail .el-icon-more {
  transform: rotate(90deg);
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.time {
  font-size: 12px;
  color: #666;
}
</style>
