<template>
  <div class="app-list-page">
    <div class="operation-panel">
      <div class="logo">My Apps</div>
      <div class="control-bar">
        <el-button class="cereb-button" @click="handleAdd" icon="el-icon-plus">
          Create an app
        </el-button>
      </div>
    </div>
    <div class="operation-site-panel">
      <slot name="operation"></slot>
    </div>
    <div class="content">
      <div class="filter">
        <span></span>
        <div class="right-region">
          <el-select v-model="sort.type" placeholder="Sort by name" class="cereb-select">
            <el-option
              v-for="item of sorts"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input prefix-icon="el-icon-search" placeholder="keywords" clearable class="cereb-input list-view-search-input" @input="delaySearch" :value="filter.keywords" />
        </div>
      </div>
      <div class="list">
        <ItemAppCard v-for="item in 3" :key="item" :item="item" />
      </div>
    </div>
    <div class="pagination-wrap">
      <span><b>{{ tableData.totalCount.toLocaleString() }}</b>&nbsp;users</span>
      <el-pagination class="cereb-pagination" background layout="prev, pager, next" @current-change="handleCurrentChange" :current-page="tableData.currentPage" :page-size="tableData.pageSize" :total="tableData.totalCount" />
    </div>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui';
import * as  api from '@/api';
import { mapActions } from "vuex";
import ItemAppCard from './components/ItemAppCard.vue';

export default {
  components: {
    ItemAppCard
  },
  data: function () {
    return {
      showUserDialog: false,
      showSiteDialog: false,
      editFormData: {},
      filter: {
        keywords: "",
      },
      sort: {
        type: 'name',
      },
      tableData: {
        currentPage: 1,
        currPage: 1,
        list: [],
        pageSize: 10,
        totalCount: 0,
        totalPage: 0,
      },
      isLoading: false
    }
  },
  computed: {
    totalItems() {
      return this.tableData.totalCount
    },
    displayTableData() {
      let currentPageUsers = this.deepClone(this.tableData.list)
      return currentPageUsers
    },
    sorts() {
      return [
        { label: 'Sort by recently updated', value: 'recently' },
        { label: 'Sort by name', value: 'name' },
      ];
    }
  },
  methods: {
    ...mapActions('siteStore', ['fetchSites', 'getSiteNameById']),
    delaySearch(keywords) {
      this.filter.keywords = keywords;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.tableData.currentPage = 1;
        this.loadUsers();
      }, 500);
    },
    getSiteNameById(id) {
      const site = this.sites.find(site => site.value === id);
      return site ? site.label : 'unset';
    },
    getStatusText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(0, 7)
      } else {
        return text
      }
    },
    getStatusButtonText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(8) === 'active' ? 'Deactivate' : 'Activate'
      } else {
        return text === 'active' ? 'Deactivate' : 'Activate'
      }
    },
    defaultErrorHandling(err) {
      console.log(err)
      if (err === 'invalid auth') {
        this.$message.error({ center: true, message: 'invalid salto authentication' });
      } else if (err === 'unauthorized') {
        this.$message.error({ center: true, message: 'login expired' });
        this.logout()
      } else {
        this.$message.error({ center: true, message: 'network error' });
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.loadUsers()
    },
    async loadUsers() {
      this.isLoading = true
      try {
        const result = await api.getUserAccounts({ offset: this.currentPage, limit: this.pageSize, siteId: this.filter.siteId, name: this.keywords });
        if (result.list == null) {
          result.list = []
        }
        this.tableData = result;
      } catch (err) {
        this.defaultErrorHandling(err);
      } finally {
        this.isLoading = false;
      }
    },
    async updateStatus(user) {
      user.status = user.status === 'active' ? 'inactive' : 'active';
    },
    handleAppendItem() {
      this.loadUsers()
    },
    handleAdd() {
      this.editFormData = {}
      this.showUserDialog = true;
    },
    handleEdit(row) {
      var index = (this.tableData.list || []).findIndex((x) => x.id === row.id);
      var editItem = this.tableData.list[index]
      Object.assign(this.editFormData, editItem)
      this.showUserDialog = true;
    },
    async handleDelete(row) {
      try {
        await MessageBox.confirm('<div style="font-size: 15px; margin-left:12px; margin-bottom:40px;">Delete?</div>', '', {
          customClass: "msgBox",
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true
        });
        const index = this.tableData.list.findIndex(user => user.email === row.email);
        if (index !== -1) {
          this.tableData.list.splice(index, 1);
        }
        this.$message.success({ center: true, message: 'success' });
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error({ center: true, message: 'fail' });
        }
        console.log('delete cancel.');
      }
    },
    formatTime(preTime) {
      if (preTime === null) {
        return 'null'
      }
      var t = new Date(preTime)
      var time = t.getFullYear() + '-' + (t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() +
        1) + '-' + (t.getDate() < 10 ? "0" + t.getDate() : t.getDate()) + ' ' + (t.getHours() < 10 ?
          "0" + t
            .getHours() : t.getHours()) + ':' + (t.getMinutes() < 10 ? "0" + t.getMinutes() : t
              .getMinutes()) + ':' + (t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds());
      return time
    },
    deepClone(obj) {
      var _obj = JSON.stringify(obj),
        objClone = JSON.parse(_obj);
      return objClone;
    },
  },
  watch: {
    "filter.siteId"() {
      this.loadUsers()
    },
  },
  mounted: function () {
  }
};
</script>

<style>
.app-list-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 25px 117px;
}

.app-list-page .operation-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.85);
}

.app-list-page .operation-panel .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.app-list-page .operation-site-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.app-list-page .operation-site-panel a {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
}

.app-list-page .operation-site-panel a:hover {
  text-decoration: underline;
}

.app-list-page .control-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-list-page .content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background-color: #182130;
  margin: 10px 0;
  padding: 10px 0;
}

.app-list-page .content .filter {
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 20px;
}

.app-list-page .content .filter .right-region {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.app-list-page .content .filter .right-region .list-view-search-input {
  width: 360px;
}

.app-list-page .list {
  padding: 20px;
  flex: 1 1 0;
  flex-wrap: wrap;
  display: flex; 
  gap: 20px;
  align-content: flex-start;
}

.app-list-page .operation-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 10px;
}

.app-list-page .pagination-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
}

.app-list-page .indicator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>
